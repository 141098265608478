import { getCookie } from 'cookieStorage'
import { LOCALE_COOKIE_NAME } from 'i18n'
import i18next from 'i18next'
import { GetServerSideProps } from 'next'
import getConfig from 'next/config'
import Head from 'next/head'
import { useEffect } from 'react'

import { getPlanById } from 'config/usePlanPrices'
import { FALLBACK_LANG } from 'lib/constants'
import { defaultOgImage } from 'lib/meta'
import { routes } from 'lib/routes'

import { useUser } from 'components/contexts/UserProvider'
import { ErrorProviderPageNotFound } from 'scenes/Errors/ErrorProviderPageNotFound'
import { ErrorSubdomainNotFound } from 'scenes/Errors/ErrorSubdomainNotFound'
import { ProviderBookingPage } from 'scenes/MyPage/Booking/ProviderBookingPage'
import { fetchActivePublicBookableServices } from 'services/api/BookableServices'
import { ProviderUserProps } from 'services/api/Users'
import {
  BookableServicesProps,
  createPage,
  ServerUserProps,
  SubdomainStatusProps,
} from 'services/auth/ssp'

const {
  publicRuntimeConfig: { PLATFORM_NAME },
} = getConfig()

type PageProps = ServerUserProps & SubdomainStatusProps & ProviderUserProps & BookableServicesProps

export const getServerSideProps: GetServerSideProps<PageProps> = async (ctx) => {
  return await createPage<BookableServicesProps>(ctx, {
    loadUser: true,
    loadSubdomainUser: true,
    loadSubdomainStatus: true,
    allowedSubdomain: 'only-custom',
    loadData: async (props) => {
      if (props.subdomainStatus) {
        try {
          const bookableServices = await fetchActivePublicBookableServices(
            props.subdomainStatus.status.user_id,
          )
          return { bookableServices }
        } catch (error) {
          console.log('provider bookable services fetch error', (error as Error).message)
        }
      }
      return {}
    },
    dataConditions: async (props) => {
      if (
        props.subdomainStatus &&
        props.bookableServices?.filter((s) => s.is_active).length === 1
      ) {
        const service = props.bookableServices.at(0)
        if (!service) return
        // Redirect to the single active bookable service from /booking page
        return {
          redirect: {
            permanent: false,
            destination: routes.booking.viewService(
              props.subdomainStatus.status.subdomain,
              service.id,
            ),
          },
        }
      }
    },
  })
}

export default function ExternalBookingPage(props: PageProps) {
  if (!props.providerUser || !props.subdomainStatus) {
    return <ErrorSubdomainNotFound {...props} currentUrl={props.currentUrl} />
  }

  if (!props.providerUser?.subscription?.should_give_access) {
    return <ErrorProviderPageNotFound />
  }

  const [user] = useUser()
  const lang =
    user?.iso_language_code ??
    getCookie(LOCALE_COOKIE_NAME) ??
    props.providerUser.iso_language_code ??
    FALLBACK_LANG

  useEffect(() => {
    const helper = async () => {
      if (i18next.language !== lang) {
        await i18next.changeLanguage(lang)
      }
    }
    helper()
  }, [])

  const t = i18next.getFixedT(lang)
  const providerPlan = getPlanById(props.providerUser.subscription.subscription_plan_idx)

  const metaTitle = t('metadata.booking.title', {
    providerName: props.providerUser.name,
    PLATFORM_NAME: PLATFORM_NAME,
    interpolation: { escapeValue: false },
  })
  const description = t('metadata.booking.description')

  return (
    <>
      <Head>
        <title>{metaTitle}</title>
        <meta key="og:title" property="og:title" content={metaTitle} />
        <meta key="description" name="description" content={description} />
        <meta key="og:description" property="og:description" content={description} />
        <link rel="canonical" href={props.currentUrl} />
        <meta key="og:image" property="og:image" content={defaultOgImage} />
        <meta key="og:image:width" property="og:image:width" content="1080" />
        <meta key="og:image:height" property="og:image:height" content="1080" />
      </Head>
      <ProviderBookingPage
        preview={false}
        services={props.bookableServices?.filter((s) => s.is_active) ?? []}
        shouldHaveBanner={!providerPlan?.policyList.hide_banner}
        providerUser={props.providerUser}
        subdomain={props.subdomainStatus.status.subdomain}
      />
    </>
  )
}
